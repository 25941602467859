<template>
  <div>
    <router-view />
  </div>
</template>
<script setup>

</script>
<style lang="scss">
html,
body,
#app {
  height: 100%;
  width: 100%;
  background-color: #f2f4f9;
}

* {
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 1279px) {
  #app {
    font-size: 12px !important;
  }
}
</style>
