import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/intercept";
import store from "./store";
import "amfe-flexible/index.js";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import Table from "@/components/table/inde.vue";
import Header from "./components/header/index.vue";
import Footer from "./components/footer/index.vue";
import '@/utils/rem.js'
const app = createApp(App);

app.use(ElementPlus).use(router).use(store).mount("#app");

app.component("Table", Table);
app.component("Header", Header);
app.component("Footer", Footer);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
