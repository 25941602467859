import { ElMessage } from "element-plus";
import { post, get } from "@/api/api";

export default {
  namespaced: true,
  state: {
    userId: "",
    userInfo: ""
  },
  mutations: {
    login: async (state, info) => {
      let res = await post("/public/login", {
        ...info.oginInput,
        userPwd: info.userPwd,
        verCodeId: info.verCodeId,
      });
      if (res.code === 200) {
        ElMessage.success(res.message);
        window.sessionStorage.setItem("access_token", res.result.access_token);
        window.sessionStorage.setItem(
          "refresh_token",
          res.result.refresh_token
        );
        window.sessionStorage.setItem("userId", res.result.userId);
        state.userId = res.result.userId;
        info.router.push("/portal");
      } else {
        ElMessage.error(res.message);
        console.log(res.message);
      }
    },
    logout: (state) => {
      window.sessionStorage.clear();
      state.userId = "";
      window.location.reload();
    },
    getUserinfo (state, info) {
      state.userInfo = info
      state.userId = window.sessionStorage.getItem("userId")
      console.log(state);
    }
  },
  actions: {},
};
