<template>
  <div class="footer-content">
    <a
      href="https://beian.miit.gov.cn/
"
      style="color: #999; text-decoration: none"
      target="_blank"
      >版权所有：榆林市榆阳区人力资源服务有限公司报名系统  陕ICP备2021012310号-1</a
    >
  </div>
</template>
<script setup></script>
<style lang="scss" scoped>
.footer-content {
  text-align: center;
  height: calc(100vh - (89vh + 0.3125rem));
  line-height: calc(100vh - (89vh + 0.3125rem));
  font-size: 0.0729rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #999999;
}
</style>
